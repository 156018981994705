import { CallMeFormNorlys } from '@src/components/CallMeForm';
import { CalculatorFooter, CalculatorFooterNorlys } from '@src/components/Footer/CalculatorFooter';
import { CalculatorHeader } from '@src/components/Header/CalculatorHeader';
import { CalculatorHeaderNorlys } from '@src/components/Header/CalculatorHeader/norlys';
import { Root } from '@src/components/Root';
import { useLeadOrigin } from '@src/hooks/useLeadOrigin';

export default function CallMePage() {
  const { origin } = useLeadOrigin();

  return origin === 'norlys' ? <CallMePageNorlys /> : <CallMePageBodil />;
}

const CallMePageBodil = () => {
  return <Root header={<CalculatorHeader />} footer={<CalculatorFooter />}></Root>;
};

const CallMePageNorlys = () => {
  return (
    <Root header={<CalculatorHeaderNorlys />} footer={<CalculatorFooterNorlys />}>
      <CallMeFormNorlys />
    </Root>
  );
};
