import { Input } from 'antd';
import { useEffect, useState } from 'react';

import { textInputPaddingNorlys } from '@src/branding/input';
import { useDisplayContext } from '@src/context/display';

import { FormElementWrapperNorlys, InputWrapper } from '../FormElementWrapper';
import { AntTextAreaProps, TextInputProps } from './types';

const { TextArea } = Input;

export const AntTextArea = ({ label, style, size, ...rest }: AntTextAreaProps) => {
  return (
    <InputWrapper {...{ label, style, size }}>
      <TextArea {...{ size, ...rest }} />
    </InputWrapper>
  );
};

export const TextInput = ({ label, onChange, attention, style, size = 'middle', ...rest }: TextInputProps) => {
  const { isMobile } = useDisplayContext();

  return (
    <InputWrapper {...{ attention, label, size, style }}>
      <Input
        {...{
          size: isMobile ? 'middle' : size,
          onChange: e => onChange?.(e.target.value),
          ...rest,
        }}
      />
    </InputWrapper>
  );
};

export const StatefulTextInput = ({
  onChange,
  style,
  size = 'middle',
  value: inputValue,
  onBlur,
  ...rest
}: TextInputProps) => {
  const { isMobile } = useDisplayContext();
  const [value, setValue] = useState<string | undefined>(inputValue as string);

  useEffect(() => setValue(inputValue as string), [inputValue]);

  return (
    <TextInput
      {...{
        value,
        size: isMobile ? 'middle' : size,
        style: { width: '100%', ...style },
        onChange: setValue,
        ...rest,
      }}
      onBlur={e => {
        onBlur?.(e);
        onChange?.(value ?? '');
      }}
    />
  );
};

export const TextInputNorlys = ({ label, onChange, attention, style, size = 'middle', ...rest }: TextInputProps) => {
  return (
    <FormElementWrapperNorlys {...{ attention, label, size, style }}>
      <Input
        {...{
          size,
          style: { width: '100%', paddingTop: textInputPaddingNorlys(size), borderWidth: '2px' },
          onChange: e => onChange?.(e.target.value),
          ...rest,
        }}
      />
    </FormElementWrapperNorlys>
  );
};
