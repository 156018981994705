import styled from 'styled-components';

import { NorlysFont } from '@src/branding';

export const TextNorlys = styled.div<{ fontType?: NorlysFont.Type; fontSize?: NorlysFont.Size; color?: string }>(
  ({ fontType = 'default', fontSize = 16, color }) => ({
    ...NorlysFont.style(fontType, fontSize),
    color: color ?? 'inherit',
  })
);
