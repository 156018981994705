import { BodilColor } from '@src/branding';
import { Anchor } from '@src/components/Anchor';
import { Text } from '@src/components/Typography';
import { useBranding } from '@src/context/branding';
import { useDisplayContext } from '@src/context/display';

import { HeaderButtons, Links } from './common';
import { HeaderBodil, Logo, LogoEDC, LogoSubtitleEDC, LogoWrapperEDC } from './style';
import { Props } from './types';

export const CalculatorHeader = (props: Props) => {
  const { isMobile, leadOrigin } = useDisplayContext();
  const { darkColor: backgroundColor, contrastLogoURL } = useBranding();

  const logoStyle = {
    width: '114px',
    left: isMobile ? '20px' : '60px',
  };

  return (
    <HeaderBodil style={{ backgroundColor }}>
      <HeaderButtons {...props} />
      {leadOrigin === 'edc' ? (
        <EDCLogo />
      ) : (
        <Anchor newTab href='https://bodil.energy/'>
          <Logo style={logoStyle} src={contrastLogoURL} />
        </Anchor>
      )}
      <Links />
    </HeaderBodil>
  );
};

const EDCLogo = () => {
  const { isMobile } = useDisplayContext();

  return (
    <LogoWrapperEDC {...{ isMobile }}>
      <Anchor newTab href='https://bodil.energy/'>
        <LogoEDC src='/logo/bodil-logo-contrast.png' />
      </Anchor>
      <LogoSubtitleEDC>
        <Text level={2} style={{ whiteSpace: 'nowrap', color: BodilColor.base.grey }}>
          i samarbejde med
        </Text>
        <img src='/logo/EDC.png' width='40px' height='40px' alt='edc logo' />
      </LogoSubtitleEDC>
    </LogoWrapperEDC>
  );
};
